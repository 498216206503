import { Modal, Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export default function ModalCancel({
  renderTitleModal,
  isShowModalCancel,
  closeModalCancel,
  dataOrderModal,
  loadingButton,
  handleSubmitBom = () => {},
  renderModalData = () => {},
  detailOrder,
}) {
  const { t } = useTranslation();
  return (
    <Modal
      centered={true}
      destroyOnClose={true}
      className="modal-cancel"
      title={renderTitleModal()}
      visible={isShowModalCancel}
      width={695}
      onCancel={closeModalCancel}
      footer={[
        <Button
          key={dataOrderModal?.id}
          block
          loading={loadingButton}
          onClick={() => handleSubmitBom(dataOrderModal)}
          style={{ backgroundColor: "#E31837" }}
          className="btn-left btn-confirm"
        >
          {!loadingButton && t("confirmBomOrder")}
        </Button>,
      ]}
    >
      {renderModalData(detailOrder, dataOrderModal)}
    </Modal>
  );
}
