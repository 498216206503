import styled from 'styled-components';
import { Colors } from 'themes/colors';

export const Text = styled.p({
    fontFamily: 'Montserrat',
    color: Colors.white,
    fontSize: 16,
    fontWeight: '400',
});

export const Text18 = styled.p({
    fontFamily: 'Montserrat',
    color: Colors.white,
    fontSize: 18,
    fontWeight: '600',
});

export const Text20 = styled.p({
    fontFamily: 'Montserrat',
    color: Colors.white,
    fontSize: 20,
    fontWeight: '600',
});

export const Text24 = styled.p({
    fontFamily: 'Montserrat',
    color: Colors.white,
    fontSize: 24,
    fontWeight: '600',
});
export const TextDark = styled.p({
    fontFamily: 'Montserrat',
    color: Colors.black,
    fontSize: 16,
    fontWeight: '400',
});
