import React from "react";
import LoginForm from "./Form";
import { Modal } from "antd";
import "./index.scss";

const PageLogin = (props) => {
  return <LoginForm />;
};

export default PageLogin;
