const { default: axios } = require("axios");

const axiosClient = axios.create({
  headers: {
    "content-type": "application/json",
  },
});

axiosClient.interceptors.request.use(async (config) => {
  const lang = localStorage.getItem("i18nextLng");

  config.headers["Accept-Language"] = lang || "en";
  return config;
});

export default axiosClient;
