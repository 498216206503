import React from "react";
import ButtonSubmit from "../../components/Button/ButtonSubmit";

export const renderFooterShipper = (
  shipperDetail,
  loadingButton,
  handleChangeAccountStatus,
  handleSubmitForm
) => {
  if (shipperDetail?.firstname?.length > 0) {
    return [
      <div key={shipperDetail?.id} style={{ display: 'flex'}}>
        <ButtonSubmit
          className="btn-disable"
          onClick={() => handleChangeAccountStatus(shipperDetail)}
          title={ shipperDetail.status !== "INACTIVE" ? "VÔ HIỆU TÀI KHOẢN" : "KÍCH HOẠT TÀI KHOẢN"}
          key={8}
          color={ shipperDetail.status !== "INACTIVE" ? "#414141": "#FFC522"}
        />
        <ButtonSubmit
          onClick={() => handleSubmitForm()}
          className="btn-save"
          loadingButton={loadingButton}
          htmlType="submit"
          title="LƯU THAY ĐỔI "
          key={6}
          color="#E31837"
        />
      </div>,
    ];
  }
  return [
    <span key={shipperDetail?.phone}>
      <ButtonSubmit
        onClick={() => handleSubmitForm()}
        loadingButton={loadingButton}
        htmlType="submit"
        title="LƯU"
        key={4}
        color="#E31837"
      />
    </span>,
  ];
};
