export const Colors = {
    black: '#000000',
    grey3: '#333333',
    white: '#ffffff',
    primary: '#FF9D00',
    darkPrimary: '#995E00',
    grey1: '#191919',
    green: '#34A853',
    grey5: '#808080',
    brown5: '#804E00',
    grey7: '#B2B2B2',
    grey8: '#CCCCCC',
    grey9: '#E5E5E5',
    primary_dark_20: '#331F00',
    red: '#EA4335',
    blueInfo: '#4285F4',
};
