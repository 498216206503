import React from "react";

function ButtonAction(props) {
  const { title, color, onClick = () => {} } = props;
  return (
    <button
      onClick={onClick}
      className="button-action"
      style={{ borderColor: color, color: color, marginInline: 5 }}
    >
      {title}
    </button>
  );
}
export default ButtonAction;
