export const viTranslation = {
  loginTitle: "VUI LÒNG ĐĂNG NHẬP",
  password: "Mật khẩu *",
  storeId: "Mã cửa hàng *",
  loginError: "Mã cửa hàng hoặc mật khẩu không đúng!",
  loginText: "ĐĂNG NHẬP",
  pending: "CHỜ XỬ LÝ",
  received: "ĐÃ NHẬN",
  cooking: "ĐANG NẤU",
  ready_to_ship: "SẴN SÀNG GIAO",
  arrived: "ĐÃ ĐẾN",
  shipping: "ĐANG GIAO",
  name: "PHẦN ĂN",
  price: "GIÁ",
  count: "SL",
  total: "THÀNH TIỀN",
  orderHistory: " LỊCH SỬ ĐƠN HÀNG",
  logout: "ĐĂNG XUẤT",
  logoutConfirmText: "Có phải bạn muốn đăng xuất khỏi ứng dụng?",
  confirmText: "XÁC NHẬN",
  version: "Phiên bản",
  cancelText: "Huỷ",
  movedFrom: "Được chuyển từ",
  orderNumber: "Mã đơn",
  receivedAt: "Nhận đơn lúc",
  item: "món",
  chanelBooking: "Kênh đặt hàng",
  Delivered: "Giao tận nơi lúc",
  storePickup: "Hẹn lấy tại cửa hàng",
  shipperAlertChange: "Đơn hàng đã được thay đổi Shipper",
  changeShipperTitle: "Thay đổi nhân viên giao hàng",
  pleaseSelectShipper: "Vui lòng chọn nhân viên giao hàng",
  shipper2order: "Shipper chỉ có thể giao 2 đơn hàng cùng lúc",
  totalOrderShipping: "Tổng đơn đang giao",
  confirmBomOrder: "XÁC NHẬN ĐƠN HÀNG BOM",
  receivedOrder: "NHẬN ĐƠN",
  reasonCancelOrder: "Vui lòng chọn lý do hủy đơn hàng",
  success: "Thành công",
  refundSucess: "Hoàn tiền thành công",
  order: "Đơn hàng",
  canceled: "đã được hủy",
  refuseOrder: "Từ chối đơn hàng",
  confirnRefuse: "XÁC NHẬN TỪ CHỐI NHẬN ĐƠN",
  goback: "QUAY LẠI",
  orderIsNotFeasible: "Đơn hàng không khả thi",
  otherReason: "Lý do khác",
  pleaseEnterReason: "Vui lý nhận lý do",
  alertNotifyPermission: "Yêu cầu bật thông báo !!!",
  alertNotifyPermission2:
    "Hãy bật thông báo của trình duyệt để nhận thông báo khi có đơn hàng.",
  newOrder: "Có đơn mới",
  failed: "Thất bại",
  notifyNewOrder: "Bạn vừa nhận được đơn hàng mới",
  orderUpdateStatus: "Đơn hàng vừa cập nhật sang trạng thái",
  confirmBom: "đã được xác nhận BOM",
  confirmFinishOrder: "XÁC NHẬN HOÀN TẤT ĐƠN HÀNG",
  confirmRefundMoney: "Xác nhận hoàn tiền cho đơn hàng",
  revenue: "DOANH THU CỬA HÀNG",
  date: "NGÀY",
  orderMethod: "Hình thức đặt hàng",
  delivery: "Giao hàng tận nơi",
  paymentMethod: "Phương thức thanh toán",
  receivedExp: "Nhận hàng dự kiến",
  deliveredAt: "Đã giao vào lúc",
  deliveryTo: "Giao đến",
  plasticEating: "(Lấy dụng cụ ăn uống nhựa)",
  itemDetail: "CHI TIẾT MÓN",
  note: "GHI CHÚ",
  provisional: "Tạm tính",
  discount: "Khuyến mãi",
  shippingFee: "Phí giao hàng",
  totalMoney: "Tổng cộng",
  moneyCollected: "Tiền thu của khách",
  reject: "TỪ CHỐI",
  order_number: "MÃ ĐƠN HÀNG",
  createAt: "NGÀY ĐẶT HÀNG",
  shippingMethod: "HÌNH THỨC ĐẶT HÀNG",
  grandTotal: "TỔNG TIỀN",
  status: "TRẠNG THÁI",
  shipper_id: "MÃ NHÂN VIÊN",
  lastname: "HỌ VÀ TÊN",
  phone: "ĐIỆN THOẠI",
  orders: "ĐANG GIAO",
  edit: "Chỉnh sửa",
  view: "Xem",
  completed: "HOÀN THÀNH",
  canceled: "ĐÃ HỦY",
  addMoreShipper: "THÊM SHIPPER",
  firstname: "Tên",
  last_name: "Họ",
  gender: "Giới tính",
  phoneNumber: "Số điện thoại",
  password: "Mật khẩu mới",
  confirmPassword: "Xác nhận mật khẩu mới",
  male: "Nam",
  female: "Nữ",
  other: "Khác",
  search: "Tìm kiếm",
  viewBill: "Xem hoá đơn",
  readyToShip: "ĐƠN HÀNG SẴN SÀNG GIAO",
  "Urgent Delivery": "Giao nhanh",
  Tax: "Tax",
  Cash: "Thanh Toán Tiền Mặt",
  cancelOrder: "HUỶ ĐƠN HÀNG",
};
