import _ from "lodash";

export function formatNumberWithCommas(number) {
  const formattedNumber = _.toNumber(number).toLocaleString("en-US");
  return formattedNumber;
}
export function roundToDecimals(num, decimals) {
  let factor = Math.pow(10, decimals);
  return Math.round(num * factor) / factor;
}
export const CURRENTCY = "USD";
