import { App, Col, Row, Spin, Modal } from "antd";
import { Button } from "../../components/atom/Button";
import { TextDark } from "../../components/atom/Text/index";
import React, { useEffect, useState } from "react";
import { Colors } from "themes/colors";
import Barcode from "react-barcode";
import {
  ButtonContainer,
  Container,
  DividedDashed,
  DividedSolid,
  RowStyled,
  text24,
  BarCodeContainer,
  text16,
  text16Bold,
  text16W,
} from "./styled";
import { formatNumberWithCommas } from "utils/format";
import { getDetailBillOrder } from "apis/Order";
import { useHistory, useLocation } from "react-router-dom";
import { usePrinter } from "handlers/printerContext";
// import { usePrinter } from "context/printerContext";
const CURRENTCY = "USD";
function useSearchParams() {
  return new URLSearchParams(useLocation().search);
}

export default function index() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const onGetData = (data) => {
    setLoading(true);
    getDetailBillOrder(data)
      .then((res) => {
        setData(res.data);
      })
      .finally(() => setLoading(false));
  };
  const searchParams = useSearchParams();
  const orderId = searchParams.get("orderId");
  const order_ID = searchParams.get("order_id");
  const navigation = useHistory();
  useEffect(() => {
    if (orderId !== null && orderId !== "undefined") {
      onGetData({ id: orderId });
    }
  }, [orderId]);
  useEffect(() => {
    if (order_ID !== null && order_ID !== "undefined") {
      onGetData({ id: orderId });
    }
  }, [order_ID]);
  const { print, connectionStatus } = usePrinter();
  const PrintBill = () => {
    if (connectionStatus === "Connected") {
      print();
      Modal.success({
        title: "Print bill Success",
        content: "Please go to printer to take the bill!",
        centered: true,
        onOk: () => {
          navigation.goBack();
        },
      });
    } else {
      Modal.error({
        title: "Print bill Failed",
        content: "Please set up printer then try again!",
        centered: true,
        onOk: () => {
          navigation.goBack();
        },
      });
    }
  };
  return (
    <Container>
      <div
        style={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          zIndex: -1,
          background: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && <Spin size="large" tip="Loading..." />}
      </div>

      {!loading && (
        <>
          <ButtonContainer>
            <Button
              style={{
                height: 56,
                width: 116,
                background: "black",
                border: `1px solid ${Colors.primary}`,
                display: "flex",
              }}
              onClick={() => navigation.goBack()}
            >
              <TextDark
                style={{
                  color: Colors.primary,
                  fontWeight: "600",
                }}
              >
                GO BACK
              </TextDark>
            </Button>
          </ButtonContainer>
          <RenderBill data={data?.orderDetail} />
          <ButtonContainer isRight>
            <Button
              style={{
                height: 56,
                width: 116,
                display: "flex",
              }}
              onClick={PrintBill}
            >
              <TextDark
                style={{
                  color: Colors.black,
                  fontWeight: "600",
                }}
              >
                Print
              </TextDark>
            </Button>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
}

const RenderBill = ({ data }) => {
  const totalDiscount =
    data?.discount?.length > 0
      ? data?.discount?.reduce((total, discount) => {
          total += discount.amount.value;
          return total;
        }, 0)
      : 0;
  return (
    <div
      style={{
        width: "320px",
        borderRadius: 8,
        background: "white",
        paddingTop: 20,
        paddingInline: 16,
        overflow: "auto",
      }}
    >
      <div id="billHeader">
        <TextDark style={text24}>{data?.firstname}</TextDark>
        <TextDark style={{ ...text16W, marginTop: 16 }}>
          {data?.address}
        </TextDark>
        <TextDark style={{ ...text16W, marginTop: 8 }}>
          Hotline: {data?.phone}
        </TextDark>

        <DividedDashed />
        <RowStyled style={{ marginTop: 20 }}>
          <TextDark>Order ID</TextDark>
          <TextDark>{data?.order_number}</TextDark>
        </RowStyled>
        <RowStyled>
          <TextDark>
            {data?.created_at && data?.created_at.split(" ")[0]}
          </TextDark>
          <TextDark>
            {data?.created_at && data?.created_at.split(" ")[1]}
          </TextDark>
        </RowStyled>
        <DividedSolid />
        <TextDark style={{ ...text16Bold, marginTop: 12 }}>
          Order summary
        </TextDark>
      </div>
      <div id="billContent">
        {data?.items?.map((item, index) => {
          return (
            <div key={index}>
              <RowStyled key={index}>
                <Col span={16} style={{ textAlign: "left" }}>
                  <TextDark style={text16}>
                    <span style={{ fontWeight: "600" }}> {item?.qty}x</span>{" "}
                    {item?.name}
                  </TextDark>
                </Col>
                <Col span={8} style={{ textAlign: "end" }}>
                  <TextDark>
                    {formatNumberWithCommas(item?.price)} {CURRENTCY}
                  </TextDark>
                </Col>
              </RowStyled>
              {item?.options?.map((option, idx) => {
                return (
                  <RowStyled
                    key={`${index}-${idx}`}
                    style={{ paddingLeft: 20 }}
                  >
                    <TextDark style={text16}>• {option?.name}</TextDark>
                  </RowStyled>
                );
              })}
            </div>
          );
        })}
      </div>
      <div id="billFooter">
        <DividedSolid />
        <TextDark style={{ ...text16Bold, marginTop: 12 }}>
          Payment detail
        </TextDark>
        <RowStyled>
          <TextDark style={text16}>Subtotal</TextDark>
          <TextDark>
            {formatNumberWithCommas(data?.grand_total + totalDiscount)}{" "}
            {CURRENTCY}
          </TextDark>
        </RowStyled>
        <RowStyled>
          <TextDark style={text16}>Discounted</TextDark>
          <TextDark>
            - {formatNumberWithCommas(totalDiscount)} {CURRENTCY}
          </TextDark>
        </RowStyled>
        {data?.discount?.length > 0 &&
          data?.discount?.map((discount, index) => {
            return (
              <Row justify={"end"} key={`Discount-${index}`}>
                <TextDark style={{ fontSize: 14 }}>
                  {discount?.label} -{" "}
                  {formatNumberWithCommas(discount?.amount?.value)}
                </TextDark>
              </Row>
            );
          })}

        <RowStyled align={"middle"}>
          <TextDark style={text16}>Total paid</TextDark>
          <TextDark style={{ fontWeight: "600", fontSize: 18 }}>
            {formatNumberWithCommas(data?.grand_total)} {CURRENTCY}
          </TextDark>
        </RowStyled>
        <RowStyled align={"middle"}>
          <TextDark style={text16}>Method</TextDark>
          <TextDark style={{ fontWeight: "600", fontSize: 18 }}>
            {data?.payment_method}
          </TextDark>
        </RowStyled>
        <BarCodeContainer>
          <Barcode value={data?.order_number} />
        </BarCodeContainer>
      </div>
    </div>
  );
};
