export const enTranslation = {
  loginTitle: "PLEASE LOG IN",
  password: "Password *",
  storeId: "Store ID *",
  loginError: "Store ID or password is incorrect!",
  loginText: "LOGIN",
  pending: "PENDING",
  received: "RECEIVED",
  cooking: "COOKING",
  ready_to_ship: "READY TO SHIP",
  shipping: "SHIPPING",
  name: "FOOD ITEM",
  price: "PRICE",
  count: "QTY",
  total: "TOTAL",
  orderHistory: "ORDER HISTORY",
  logout: "LOG OUT",
  logoutConfirmText: "Are you sure you want to log out?",
  confirmText: "CONFIRM",
  version: "Version",
  cancelText: "Cancel",
  movedFrom: "Moved from",
  orderNumber: "Order Number",
  receivedAt: "Received At",
  item: "item",
  chanelBooking: "Order channel",
  Delivered: "Delivered on time",
  storePickup: "Appointment at the store",
  shipperAlertChange: "Order has been changed Shipper",
  changeShipperTitle: "Change delivery staff",
  pleaseSelectShipper: "Please select delivery staff",
  shipper2order: "Shipper can only deliver 2 orders at a time",
  totalOrderShipping: "Total orders being shipped",
  confirmBomOrder: "CONFIRM BOM ORDER",
  receivedOrder: "RECEIVE ORDER",
  reasonCancelOrder: "Please select the reason for canceling the order",
  success: "Success",
  refundSucess: "Refund successful",
  order: "Order",
  canceled: "has been canceled",
  refuseOrder: "Refuse order",
  confirnRefuse: "CONFIRM REFUSE TO RECEIVE ORDER",
  goback: "GO BACK",
  orderIsNotFeasible: "Order is not feasible",
  otherReason: "Other reasons",
  pleaseEnterReason: "Please enter the reason",
  alertNotifyPermission: "Request to turn on notifications!!!",
  alertNotifyPermission2:
    "Please enable browser notifications to receive alerts when orders arrive.",
  newOrder: "New order",
  failed: "Failed",
  notifyNewOrder: "You have received a new order",
  orderUpdateStatus: "The order has just been updated to",
  confirmBom: "has been confirmed BOM",
  confirmFinishOrder: "CONFIRM COMPLETION OF ORDER",
  confirmRefundMoney: "Confirm refund for order",
  revenue: "STORE REVENUE",
  date: "DATE",
  orderMethod: "Order method",
  delivery: "Home delivery",
  paymentMethod: "Payment method",
  receivedExp: "Expected delivery",
  deliveredAt: "Delivered at",
  deliveryTo: "Delivery to",
  plasticEating: "(Plastic eating utensils)",
  itemDetail: "ITEM DETAIL",
  note: "NOTE",
  provisional: "Provisional",
  discount: "Discount",
  shippingFee: "Shipping fee",
  totalMoney: "Total",
  moneyCollected: "Customer collected",
  reject: "REJECT",
  order_number: "ORDER NUMBER",
  createAt: "ORDER DATE",
  shippingMethod: "ORDER METHOD",
  grandTotal: "GRAND TOTAL",
  status: "STATUS",
  shipper_id: "SHIPPER ID",
  lastname: "LAST NAME",
  phone: "PHONE",
  orders: "ORDERS",
  edit: "EDIT",
  view: "VIEW",
  completed: "COMPLETED",
  canceled: "CANCELED",
  addMoreShipper: "ADD SHIPPER",
  firstname: "First Name",
  last_name: "Last Name",
  gender: "Gender",
  phoneNumber: "Phone Number",
  password: "Password",
  confirmPassword: "Confirm Password",
  male: "Male",
  female: "Female",
  other: "Other",
  search: "Search",
  viewBill: "View Bill",
  readyToShip: "Ready to ship",
  "Urgent Delivery": "Urgent Delivery",
  Tax: "Tax",
  Cash: "Cash",
  cancelOrder: "CANCEL ORDER",
};
