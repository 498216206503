import { callApi } from "..";

export const apiGetNotificationList = (data) =>
  callApi(
    `query ($pageSize: Int!, $currentPage: Int!) {
      getMerchantNotificationList(pageSize: $pageSize,  currentPage: $currentPage) {
        items {
          notification_id
          type
          order_id
          title
          content
          created_date
        }
        page_info {
          page_size
          current_page
          total_pages
        }
      }
    }`,
    { type: data?.type, pageSize: data?.pageSize ?? 20, currentPage: data?.currentPage ?? 1 }
  );

export const apiMakeReadNotification = (data) =>
  callApi(
    `mutation ($id: Int!) {
    markReadNotification(id: $id) {
      result
    }
  }`,
    data
  );

export const apiMakeReadAllNotification = (data) =>
  callApi(
    `mutation {
      markReadAllNotification {
      result
    }
  }`,
    data
  );
