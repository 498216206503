import { callApi } from "..";


export const apiGetShipperDetail = () => {
  callApi(
    `{
      getShipperInfo {
        id
        firstname
        lastname
        accept_order
        restaurant
        address
      }
    }`,
    null
  )
}

export const apiGetShipperList = () =>
  callApi(
    `{
      shipperList {
          shipper {
              shipper_id
              lastname
              firstname
              phone
              status
              gender
  
          }
      }
  }`,
    null
  );

export const apiAssignShipper = (data) =>
  callApi(
    `mutation ($shipperId: Int!, $orderId: Int!){
    assignShipper(shipperId: $shipperId, orderId: $orderId) {
      result
    }
  }`,
    data
  );

export const apiCreateShipper = (data) =>
  callApi(
    `mutation (
    $password: String!
    $firstname: String!
    $lastname: String!
    $username: String!
    $gender: String!
    $phone: String!
  ) {
    createShipperAccount (
      password: $password
      firstname: $firstname
      lastname: $lastname,
      username:$username
      gender:$gender
      phone:$phone
    ) {
      result
    }
  }`,
    data
  );

export const apiUpdateShipper = (data) =>
  callApi(
    `mutation (
      $shipper_id: String!
      $firstname: String!
      $lastname: String!
      $phone: String!
      $gender: String!
      $password: String!
      $status: String!
    ) {
      updateShipperInfo (
        shipper_id: $shipper_id
        firstname: $firstname
        lastname: $lastname
        phone: $phone
        gender: $gender
        password: $password
        status: $status
      ) {
        result
      }
    }`,
    data
  );

export const enableShipper = (data) =>
  callApi(
    `mutation ($shipperId: Int!, $status: Int!) {
    enableShipper (shipper_id: $shipperId, status: $status) {
      result
    }
  }`,
    data
  );
export const merchantAcceptShipping = (data) =>
  callApi(
    `mutation ($id: Int!, $type: Int!) {
      merchantAcceptShipping(id: $id, type: $type) {
        result
      }
    }`,
    data
  )